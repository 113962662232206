var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('modal',{attrs:{"action-text":_vm.$t(("message.relations.actions." + _vm.action)),"confirm-text":_vm.$t(("message.relations.actions.confirm." + _vm.action)),"confirm-disabled":invalid,"delete-text":_vm.action === 'edit' ? _vm.$t('message.relations.actions.delete') : null,"error":_vm.error,"loading":_vm.loading},on:{"confirm-clicked":_vm.clickAction,"close":function($event){return _vm.$emit('close')},"delete-clicked":_vm.deleteAction},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-col items-center justify-center"},[_c('div',[_c('p',{staticClass:"pb-2 -ml-1 text-sm font-medium text-black capitalize fontFamily"},[_vm._v(_vm._s(_vm.$t('message.tableHeaders.name')))]),_vm._v(" "),_c('vl-input',{attrs:{"name":"firstName","rules":"required"},model:{value:(_vm.data['firstName']),callback:function ($$v) {_vm.$set(_vm.data, 'firstName', $$v)},expression:"data['firstName']"}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center justify-center mt-2"},[_c('div',[_c('p',{staticClass:"py-2 -ml-1 text-sm font-medium text-black capitalize fontFamily"},[_vm._v(_vm._s(_vm.$t('message.tableHeaders.lastName')))]),_vm._v(" "),_c('vl-input',{attrs:{"name":"lastName","rules":"required"},model:{value:(_vm.data['lastName']),callback:function ($$v) {_vm.$set(_vm.data, 'lastName', $$v)},expression:"data['lastName']"}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center justify-center mt-2"},[_c('div',[_c('p',{staticClass:"py-2 -ml-1 text-sm font-medium text-black capitalize fontFamily"},[_vm._v(_vm._s(_vm.$t('message.tableHeaders.rut')))]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"rut","rules":['required', 'customAlphanumericRut']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data['rut']),expression:"data['rut']"},{name:"alphanumeric-rut",rawName:"v-alphanumeric-rut"}],staticClass:"px-2 -ml-1 inputStyle",class:{
                  'border-gray-400 focus:border-vl-blue': errors.length == 0,
                  'border-red-500 focus:border-red-600': errors.length > 0,
                },attrs:{"type":"text","placeholder":_vm.$t("message.placeholders.rut")},domProps:{"value":(_vm.data['rut'])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, 'rut', $event.target.value)}}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center justify-center mt-2"},[_c('div',[_c('p',{staticClass:"py-2 -ml-1 text-sm font-medium text-black capitalize fontFamily"},[_vm._v(_vm._s(_vm.$t('message.tableHeaders.phone')))]),_vm._v(" "),_c('vl-input',{attrs:{"name":"phone"},model:{value:(_vm.data['phone']),callback:function ($$v) {_vm.$set(_vm.data, 'phone', $$v)},expression:"data['phone']"}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col items-center justify-center pb-8 mt-2"},[_c('div',[_c('p',{staticClass:"py-2 -ml-1 text-sm font-medium text-black capitalize fontFamily"},[_vm._v("\n              "+_vm._s(_vm.$t('message.tableHeaders.email'))+"\n            ")]),_vm._v(" "),_c('vl-input',{attrs:{"name":"email"},model:{value:(_vm.data['email']),callback:function ($$v) {_vm.$set(_vm.data, 'email', $$v)},expression:"data['email']"}})],1)])])]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }