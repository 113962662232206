export default {
  message: {
    actions: {
      backToRelations: 'Volver a las relaciones',
    },
    accounts: {
      actions: {
        create: 'Agregar Cuenta',
        delete: 'Eliminar cuenta',
        edit: 'Editar cuenta',
        confirm: {
          create: 'Agregar',
          delete: `¿Estas seguro que quieres eliminar la cuenta?\n
          De ser asi haz click de nuevo en el botón eliminar.`,
          edit: 'Guardar',
        },
      },
      selectRelation: 'Seleccione una relación para ver sus cuentas o agregue una.',
    },
    clientBalances: {
      accounts: {
        selectTitle: 'Cuentas de inversión',
        clear: 'Borrar',
        cancel: 'Cancelar',
        edit: 'Editar',
        save: 'Guardar',
        selectAll: 'Seleccionar todas',
        selectedNone: 'Ninguna cuenta seleccionada',
        selectedNumber: '{number} cuentas seleccionadas',
        selectedOne: '1 cuenta seleccionada',
      },
      chart: {
        deleteDots: 'Eliminar puntos',
        deleteDotsModal: {
          action: 'Confirmación de puntos a eliminar',
          cancel: 'Cancelar',
          delete: 'Eliminar puntos',
          errorText: 'No se pudieron eliminar los puntos del gráfico en este momento. Por favor, intente más tarde.',
          errorTitle: 'Error en la solicitud',
          deleteDotsConfirmation: '¿Estás seguro/a que quieres eliminar los siguientes puntos?:\n {dots}',
        },
        nextUpdate: 'Próxima actualización automática programada para {time}',
        noCharts: 'Seleccione una o más cuentas para ver el evolutivo del consolidado',
        info: `El capital consolidado y rentabilidad son estimaciones y podrían tener mínimas variaciones con las cartolas de inversiones bancarias.`,
        infoPension: `El capital consolidado y rentabilidad son estimaciones y podrían tener mínimas variaciones con las cartolas.`,
        loadingHistory: `Aún no se tienen datos de su consolidado histórico,
                        estos se estan cargando. Por favor, revise más tarde.`,
        timeWindows: {
          mtd: 'Month To Date',
          '1m': '1 Month',
          '3m': '3 Months',
          '6m': '6 Months',
          ytd: 'Year To Date',
          '1y': '1 Year',
          '3y': '3 Years',
          '5y': '5 Years',
        },
      },
      consolidatedCapital: {
        name: 'Capital consolidado',
        info: 'Capital total en inversiones después de lo depositado/\nretirado y/o ganado/perdido al cierre más reciente. \n Último cierre: {date}',
      },
      consolidatedCapitalPensionFunds: {
        name: 'Capital consolidado',
        info: 'Capital total en fondos de pensiones después de lo ganado/perdido al cierre más reciente disponible. \n Último cierre: {date}',
      },
      difference: {
        name: 'Diferencia',
        info: 'Diferencia del capital consolidado menos el neto depositado',
      },
      multiselectBanks: {
        banks: 'Instituciones',
      },
      totalDeposits: {
        name: 'Depósitos - Retiros',
        info: 'Diferencia de capitales depositados menos los retirados',
      },
    },
    footer:{
      powered_by: "Powered by",
      vl_name: "Valuelist",
      see_previous_version: "Para visualizar la plataforma en su nueva versión,",
      click_here: "haz click aquí"
    },
    documentFiles: {
      add: {
        button: 'Agregar documento',
        edit: 'Reemplazar documento',
        description: 'Agrega un documento (PDF, PNG o JPG)',
      },
      create: 'Cargar Documento',
      confirmDelete: `¿Estas seguro que quieres eliminar el documento?\n
                      De ser asi haz click de nuevo en el botón eliminar.`,
      delete: 'Eliminar documento',
      documents: 'Documentos',
      download: 'Descargar',
      edit: 'Editar documento',
      error: 'Debe subir un archivo y definirle un nombre',
      selectRelation: 'Seleccione una relación para ver sus documentos o agregue uno.',
      confirm: {
        create: 'Cargar',
        delete: 'Eliminar documento',
        edit: 'Guardar',
      },
    },
    multiselect: {
      all: 'Todas',
    },
    investmentAssets: {
      alternativeAsset: 'Activo alternativo',
      fixedIncome: 'Renta fija',
      variableIncome: 'Renta variable',
      pensionFund: 'Fondo de pensión',
      balancedAsset: 'Activo balanceado',
      realEstate: 'Bien raíz',
      structuredAsset: 'Activo estructurado',
      forwardAsset: 'Activo forward',
      others: 'Otros',
      moneyMarket: 'Money Market',
      ticker: 'Instrumento (ticker)',
      all: 'Todos',
      notFound: '¿No está el instrumento que buscas?',
      create: {
        add: 'Agregar',
        new: 'Nuevo Instrumento',
        create: 'Crear',
        suggest: 'Sugerir',
      },
    },
    realEstateAssets: {
      name: 'Nombre',
      role: 'Rol',
      comune: 'Comuna',
      investedBalance: 'Inversión Total',
      institution: 'Institución',
      date: 'Fecha',
      create: {
        addExternal: 'Agregar Valoración',
        add: 'Agregar',
      },
    },
    memberships: {
      selectSubAccount: 'Seleccione una sub cuenta para ver sus instrumentos o agregue uno.',
    },
    metrics: {
      backToBalance: 'Volver al resumen',
      concentration: 'Concentración',
      metrics: 'Desempeño Instrumentos',
      performance: 'Desempeño',
    },
    moneyMovements: {
      name: 'Movimientos',
      type: {
        purchase: 'Compra',
        sale: 'Venta',
      },
      actions: {
        delete: 'Eliminar movimiento',
        confirm: {
          delete: `¿Estas seguro que quieres eliminar el movimiento?\n
          De ser asi haz click de nuevo en el botón eliminar.`,
        },
        create: {
          liquidate: 'Liquidar',
          purchase: 'Comprar',
          sale: 'Vender',
          update: 'Actualizar',
        },
        edit: {
          purchase: 'Guardar',
          sale: 'Guardar',
        },
        hide: 'Esconder instrumento',
      },
    },
    months: {
      1: 'Enero',
      2: 'Febrero',
      3: 'Marzo',
      4: 'Abril',
      5: 'Mayo',
      6: 'Junio',
      7: 'Julio',
      8: 'Agosto',
      9: 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre',
    },
    entities: {
      account: 'Cuenta | Cuentas',
      subAccount: 'Sub cuenta | Sub cuentas',
    },
    errors: {
      email: 'Ingresa un correo electrónico con un formato válido',
      memberships: 'Debes elegir una cuenta y un portafolio primero para visualizar los instrumentos.',
      phone: 'Ingresa un celular con un formato válido',
      subAccounts: 'Debes elegir una cuenta primero para visualizar los portafolios.',
      required: 'Este campo es obligatorio',
      rut: 'Ingresa un rut con un formato válido',
    },
    paginator: {
      currentPage: 'En página {currentPage} de {totalPages}',
    },
    placeholders: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      rut: '11111111-1',
      phone: '+56911111111',
      email: 'email@ejemplo.cl',
    },
    priceChange: {
      new: 'Nuevo precio',
      update: 'Actualizar',
    },
    reports: {
      yearAccumulated: 'Acumulado {year}',
      monthlyReport: 'Reporte mes a mes',
      monthlyReportError: 'Seleccione una cuenta y sub cuenta para poder ver detalle del reporte mes a mes.',
      noAccounts: 'No tiene cuentas creadas. Hable con su asesor de inversiones.',
      selectAccount: 'Seleccione una cuenta para ver sus sub cuentas.',
      yearSummary: 'Resumen {year}',
    },
    relations: {
      actions: {
        create: 'Agregar Relación',
        delete: 'Eliminar relación',
        edit: 'Editar Relación',
        confirm: {
          create: 'Agregar',
          delete: `¿Estas seguro que quieres eliminar la relación?\n
          De ser asi haz click de nuevo en el botón eliminar.`,
          edit: 'Guardar',
        },
      },
    },
    subAccounts: {
      actions: {
        create: 'Agregar Subcuenta',
        delete: 'Eliminar',
        edit: 'Editar subcuenta',
        confirm: {
          create: 'Agregar',
          delete: `¿Estas seguro que quieres eliminar la subcuenta?\n
          De ser asi haz click de nuevo en el botón eliminar.`,
          edit: 'Guardar',
        },
      },
      selectAccount: 'Seleccione una cuenta para ver sus sub cuentas o agregue una.',
    },
    realEstates: {
      actions: {
        create: 'Agregar',
        delete: 'Eliminar',
        edit: 'Editar',
      },
    },
    tableHeaders: {
      account: 'Cuenta',
      deposits: 'Aportes',
      accountType: 'Tipo cuenta',
      endCapital: 'Capital Final',
      initialCapital: 'Capital Inicial',
      month: 'Mes',
      profitAbsolute: 'Rentabilidad ($)',
      profitRelative: 'Rentabilidad (%)',
      withdrawals: 'Retiros',
      amount: 'Monto',
      comment: 'Comentario',
      assetId: 'Instrumento',
      date: 'Fecha',
      name: 'Nombre',
      quotasBalance: 'Cantidad',
      quotaAveragePrice: 'Costo',
      quotaAveragePriceTooltip: 'Precio promedio de compra',
      amountInvestedBalance: 'Inversión \n Total',
      amountInvestedBalanceTooltip: 'Inversión total',
      updatedQuotaAveragePrice: 'Precio Cierre',
      amountUpdatedBalance: 'Monto Actual',
      incomesBalanceAndPercentage: 'Plusvalía Y \n Rentabilidad',
      incomesBalance: 'Plusvalía',
      incomesPercentage: 'Rentabilidad',
      incomesPercentageTooltip: 'Ganancia / Pérdida',
      profitability: 'Rentab.',
      profitabilityToolTip: 'Rentabilidad: Ganancias / Pérdidas',
      rut: 'RUT',
      email: 'Correo electrónico',
      bank: 'Banco',
      subAccountId: 'Identificador',
      propertyType: 'Tipo de Propiedad',
      currency: 'Moneda',
      relativeWeight: 'Peso',
      relativeWeightToolTip: 'Peso relativo sobre el total de tu patrimonio',
      relativeWeightInvesmentsToolTip: 'Peso relativo sobre el monto actual del portafolio',
      averageAnnualCost: '% Comisión',
      averageAnnualCostToolTip: 'Porcentaje de costo de administración del instrumento',
      annualCost: 'Comisión',
      annualCostToolTip: 'Costo de administración del instrumento',
      mtd: 'MTD',
      mtdToolTip: 'Month To Date',
      qtd: 'QTD',
      qtdToolTip: 'Quarter To Date',
      ytd: 'YTD',
      ytdToolTip: 'Year To Date',
      y1: '1Y',
      y1ToolTip: '1 Year',
      y3: '3Y',
      y3ToolTip: '3 Years Annualized',
      y5: '5Y',
      y5ToolTip: '5 Years Annualized',
      recoveryLevel: 'Recuperación',
      recoveryLevelTooltip: 'Recuperación desde el inicio de la pandemia el día 23/03/2020',
      subSector: 'Sub sector',
      assetType: 'Tipo de activo',
      etfPatrimony: 'Monto Actual',
      etfPatrimonyToolTip: 'Patrimonio Instrumento',
      assetQuotaAveragePrice: 'Costo',
      assetQuotaAveragePriceToolTip: 'Precio promedio de compra del instrumento',
      averagePrice: 'Precio promedio',
      phone: 'Celular',
      firstName: 'Nombre',
      fullName: 'Nombre completo',
      nameOrEnterprise: 'Nombre o Empresa',
      lastName: 'Apellido',
      type: 'Tipo',
      movementType: 'Tipo de movimiento',
      ticker: 'Instrumento (ticker)',
      subAccount: 'Sub cuenta',
      institution: 'Institución',
      documentName: 'Nombre documento',
      documentType: 'Tipo documento',
      showDeposits: 'Mostrar depósitos y retiros',
      show: 'Ver',
      edit: 'Editar',
      nameRe: 'Nombre/Rol',
      role: 'Rol',
      assetDestination: 'Destino del Activo',
      comune: 'Comuna',
      area: 'Cantidad M²',
      areaAveragePrice: 'Costo M²',
      amountInvestedBalanceRe: 'Inversión \r Total',
      fiscalAppraise: 'Avalúo Fiscal',
      vlValorization: 'Valorización Valuelist',
      externalValuation: 'Valorización Externa',
      incomesBalanceRe: 'Plusvalía',
      incomesPercentageRe: 'Rentabilidad',
      relativeWeightRe: 'Peso',
      valuationTypeExternal: 'Externa',
      valuationTypeInternal: 'Interna',
      valuation: 'Valorización',
      valuationType: 'Tipo de Valorización',
      valuationName: 'Empresa',
      external: 'Externa',
      internal: 'Interna',
    },
    titles: {
      documents: 'Documentos',
      investmentsSummary: 'Inversiones',
      movementsHistory: 'Historial de movimientos de {relationName}',
      report: 'Reporte',
      assetsMetrics: 'Desempeño Instrumentos',
      investments: 'Inversiones',
      pensionFunds: 'Fondos de pensiones',
      realEstate: 'Bienes Raíces',
    },
    walletMovements: {
      type: {
        deposit: 'Depósito',
        deposits: 'Depósitos',
        noMonthDeposits: 'No hay depósitos en este mes.',
        withdraw: 'Retiro',
        withdrawals: 'Retiros',
        noMonthWithdrawals: 'No hay retiros en este mes.',
      },
      actions: {
        confirm: {
          delete: `¿Estas seguro que quieres eliminar el movimiento?\n
          De ser asi haz click de nuevo en el botón eliminar.`,
        },
        delete: 'Eliminar movimiento',
        deposit: 'Depositar',
        edit: 'Guardar',
        withdraw: 'Retirar',
      },
      selectSubAccount: {
        deposit: 'Seleccione una sub cuenta para ver sus depósitos o agregue uno.',
        withdrawal: 'Seleccione una sub cuenta para ver sus retiros o agregue uno.',
      },
    },
  },
};
