var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"justify-between w-full table-fixed tableMin text-vl-gray-400 min-h borderlistos"},[_c('thead',{},[(_vm.showHeaders)?_c('tr',{staticClass:"text-vl-gray-400 "},[(_vm.withCheckbox)?_c('th',{staticClass:"sticky top-0 z-10 w-8 uppercase bg-white border-t-2 border-b-2 text-vl border-vl-value borderlistos"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allCheckboxSelected),expression:"allCheckboxSelected"}],staticClass:"w-full px-2 border-secondary focus:outline-none",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.allCheckboxSelected)?_vm._i(_vm.allCheckboxSelected,null)>-1:(_vm.allCheckboxSelected)},on:{"change":[function($event){var $$a=_vm.allCheckboxSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allCheckboxSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allCheckboxSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allCheckboxSelected=$$c}},_vm.toggleAllCheckboxSelected]}})]):_vm._e(),_vm._v(" "),_vm._l((_vm.finalHeaders),function(header,index){return _c('th',{key:index,staticClass:"sticky top-0 py-2 font-medium bg-white border-t-2 border-b-2 border-vl-value",style:(("width: " + (header.columnWidth) + "px"))},[_c('div',{staticClass:"flex items-center justify-between text-left"},[(header.title)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                content: header.headerTooltip ? _vm.$t(("message.tableHeaders." + (header.headerTooltip))) : '',
                classes: ['bg-vl-gray text-white px-6 bg-opacity-90 py-2 mb-2 rounded shadow-lg max-w-xs z-20']
              }),expression:"{\n                content: header.headerTooltip ? $t(`message.tableHeaders.${header.headerTooltip}`) : '',\n                classes: ['bg-vl-gray text-white px-6 bg-opacity-90 py-2 mb-2 rounded shadow-lg max-w-xs z-20']\n              }",modifiers:{"top":true}}],staticClass:"pl-2 uppercase text-vl"},[_vm._v("\n              "+_vm._s(_vm.$t(("message.tableHeaders." + (header.title))))+"\n            ")]):_vm._e(),_vm._v(" "),(header.title && _vm.withSort && _vm.sortedData.length)?_c('div',{staticClass:"flex flex-col items-center mr-1"},[_c('inline-svg',{staticClass:"font-black mb-1 w-2 h-1.5 cursor-pointer fill-current",class:[_vm.criteriaIsSelected(1, header.title) ? 'text-vl-gray-650' : 'text-vl-gray-400'],attrs:{"src":require('assets/images/icons/ArrowUp.svg')},on:{"click":function($event){return _vm.toggleSort({ direction: 1, header: header})}}}),_vm._v(" "),_c('inline-svg',{staticClass:"font-black w-2 h-1.5 cursor-pointer fill-current",class:[_vm.criteriaIsSelected(-1, header.title) ? 'text-vl-gray-650' : 'text-vl-gray-400'],attrs:{"src":require('assets/images/icons/ArrowDown.svg')},on:{"click":function($event){return _vm.toggleSort({ direction: -1, header: header})}}})],1):_vm._e()])])}),_vm._v(" "),(_vm.showView)?_c('th',{staticClass:"sticky top-0 w-16 bg-white"},[_c('span',{staticClass:"font-normal"},[_vm._v("\n            D/R\n          ")])]):_vm._e(),_vm._v(" "),(_vm.showEdit)?_c('th',{staticClass:"sticky top-0 z-10 w-16 font-medium uppercase bg-white border-t-2 border-b-2 text-vl border-vl-value borderlistos"},[_vm._v("\n          Editar\n        ")]):_vm._e()],2):_vm._e()]),_vm._v(" "),_vm._l((_vm.sortedData),function(row,key){return _c('tr',{key:key,staticClass:"`break-words text-vl text-vl-gray-400`",class:[
        (_vm.noHasHover === false ? '' : 'hover:bg-vl-blue-light'),
        ((_vm.noHasHover && _vm.selectedRow === key) ? 'bg-vl-blue-light' : ''),
        (_vm.validatedSelectedCheckboxesId(row.id) ? 'bg-vl-blue-light' : '')
      ],on:{"click":function($event){return _vm.rowClicked(row.id, key)}}},[(_vm.withCheckbox)?_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rowCheckboxes[row.id]),expression:"rowCheckboxes[row.id]"}],staticClass:"w-full px-2 border-secondary focus:outline-none",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.rowCheckboxes[row.id])?_vm._i(_vm.rowCheckboxes[row.id],null)>-1:(_vm.rowCheckboxes[row.id])},on:{"click":[function($event){return _vm.onClickCheckbox(row.id)},function($event){$event.stopPropagation();}],"change":[function($event){var $$a=_vm.rowCheckboxes[row.id],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.rowCheckboxes, row.id, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.rowCheckboxes, row.id, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.rowCheckboxes, row.id, $$c)}},function($event){return _vm.$emit('checkboxes-updated', _vm.selectedCheckboxRowIds)}]}})]):_vm._e(),_vm._v(" "),_vm._l((_vm.headers),function(header,index){return _c('td',{directives:[{name:"tooltip",rawName:"v-tooltip.right-end",value:({
          content: header.tooltip ? _vm.tooltipText(row, header) : '',
          classes: ['bg-vl-gray text-white px-6 bg-opacity-90 py-2 rounded shadow-xl max-w-xs z-20']
        }),expression:"{\n          content: header.tooltip ? tooltipText(row, header) : '',\n          classes: ['bg-vl-gray text-white px-6 bg-opacity-90 py-2 rounded shadow-xl max-w-xs z-20']\n        }",modifiers:{"right-end":true}}],key:index,staticClass:"py-2 pl-2 tracking-wide",class:(" " + ((key === _vm.sortedData.length - 1) && _vm.lastRowTotal ? (" text-vl font-bold border-b-2 border-vl-value " + (_vm.lastRowTotalByCurrency ? ("" + _vm.lastRowTotalByCurrencyClass) : "bg-vl-gray-50")) : "") + " "),style:('width: '+ header.columnWidth + 'px')},[((header.filter && row[header.title] !== '-') || (header.multiFilters && header.titles))?_c('span',{domProps:{"innerHTML":_vm._s(_vm.rowTextWithFilter(row, header))}}):_c('span',{staticClass:"w-full"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.rowText(row, header))}})])])}),_vm._v(" "),(_vm.showView)?_c('td',{on:{"click":function($event){$event.stopPropagation();return _vm.viewClicked(row.id)}}},[_c('inline-svg',{staticClass:"cursor-pointer fill-current text-vl-gray-300 hover:text-vl-blue",attrs:{"src":require('assets/images/icons/view.svg')}})],1):_vm._e(),_vm._v(" "),(_vm.showEdit)?_c('td',{on:{"click":function($event){$event.stopPropagation();return _vm.editClicked(row.id)}}},[_c('inline-svg',{staticClass:"cursor-pointer fill-current text-vl-gray-300 hover:text-vl-blue ml-4",attrs:{"src":require('assets/images/icons/edit2.svg')}})],1):_vm._e()],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }