var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('line-chart',{staticClass:"mt-4",attrs:{"height":_vm.height,"chart-data":_vm.chartData,"options":Object.assign({}, _vm.options,
      {tooltips: {
        custom: function (tooltip) {
          if (!tooltip) { return; }
          tooltip.displayColors = false;
        },
        callbacks: {
          title: function (data) { return _vm.formatXTick(data[0].label); },
          label: function (data) { return _vm.formatYTick(data.yLabel); },
        },
        backgroundColor: 'rgb(129, 129, 129, 0.9)'
      },
      legend: { display: false },
      onClick: function (_, item) { return _vm.dotClick(item); },
      scales: Object.assign({}, _vm.scalesOptions,
        {yAxes: [{
          position: 'right', ticks: Object.assign({}, {callback: function (value) {
              var _ = [], len = arguments.length - 1;
              while ( len-- > 0 ) _[ len ] = arguments[ len + 1 ];

              return _vm.formatYTick(value);
}}, _vm.yAxesTicksOptions)
        }],
        xAxes: [{
          ticks: { callback: function (value) {
              var _ = [], len = arguments.length - 1;
              while ( len-- > 0 ) _[ len ] = arguments[ len + 1 ];

              return _vm.formatXTick(value);
} }
        }]})})}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }